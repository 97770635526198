<template>
  <div class="box">
    <div class="justify-content-center" style="width: 80%;margin: 0 auto;">
      <div class="tab-search">
        <div class="row justify-content-between ml-3 mr-3 types">
          <div class="invisible" style="padding-left:15px;">
            <img src="../../assets/img/search/3.0sxtk_search_icon_search.png" alt />
          </div>
          <div class="col" v-if="isshow">
            <!-- <router-link
              :to="{name:'DebtSearchResult'}"
              class="sear-tab-darks fz-14 cursor_pionter br_2 color-le mr-18"
              tag="span"
            >上市企业</router-link> -->
            <!-- <router-link
              :to="{name:'CustomResult'}"
              ref="a"
              @click.native="rootUser()"
              class="sear-tab-darks text-indent-right color-le fz-14 cursor_pionter br_2"
              tag="span"
              v-show="!isVIP"
            >
              我的企业
              <img src="../../assets/img/search/vip-icon.png" class="ml-1" alt />
            </router-link>

            <router-link
              :to="{name:'CustomResult'}"
              class="sear-tab-darks text-indent-right color-le fz-14 cursor_pionter br_2 mb_myqiye"
              tag="span"
              v-show="isVIP"
            >
              我的企业
              <img src="../../assets/img/search/vip-icon.png" class="ml-1" alt />
            </router-link> -->
          </div>
        </div>
      </div>
    </div>
    <keep-alive>
      <router-view></router-view>
    </keep-alive>

    <vdialog v-bind:dialogData="comData" ref="dialogConfirm"></vdialog>
  </div>
</template>

<script>
import dialog from "../dialog/dialog";
export default {
  name: "",
  data() {
    return {
      hotWords: [
        { hot: "东风汽车" },
        { hot: "东风汽车" },
        { hot: "东风汽车" },
        { hot: "东风汽车" },
      ],
      comData: {},
      a: "VIP",
      titleString: "",
      changeRou: "false",
      isVIP: false,
    };
  },
  components: {
    vdialog: dialog,
  },
  methods: {
    rootUser(ev) {
      this.$router.push({ name: "DebtSearchResult" });
      // console.log(this.$store.state.isLogin.loginStore)
      if (this.$store.state.isLogin.loginStore == null) {
        //console.log(1);
        this.$refs.dialogConfirm.showDialog();
        this.comData = {
          iconType: "noLogin",
          msg: "您还未登录，查看受限！",
          tips: "请登录后查看更多内容......",
          rightText: "立即登录",
          lefText: "不了,谢谢",
          nextRouter: "/login",
          vipTips: "true",
        };
      } else {
        if (this.$store.state.isLogin.loginStore.user_role == 1) {
          //console.log(2);
          this.$refs.dialogConfirm.showDialog();
          this.comData = {
            iconType: "vip",
            rightText: "好的",
            lefText: "不了,谢谢",
            nextRouter: "/login", //路由跳转到升级VIp的路由
            vipTips: "true",
          };
          this.$router.push("/DebtSearchResult");
        } else {
          //console.log(3);
          this.$router.push("/custom");
        }
      }
    },
  },

  computed: {
    isshow() {
      if (
        this.$route.name == "DebtSearchResult" ||
        this.$route.name == "CustomResult"
      )
        return true;
      else return false;
    },
  },
  created() {
    this.$store.state.showNav = true;
    this.$store.state.showFooter = true;
    if (this.$store.state.isLogin.loginStore != null) {
      if (this.$store.state.isLogin.loginStore.user_role == 1) {
        this.isVIP = false;
      } else if (this.$store.state.isLogin.loginStore.user_role == 2) {
        this.isVIP = true;
      }
    }
  },
};
</script>


<style lang="less">
.box {
  width: 85%;
  margin: 0 auto;
}

.search-hot-title {
  color: #1270ff;
  width: 60px;
  height: 26px;
  line-height: 26px;
  background-color: #c4dbfe;
  border-radius: 20px;
}
.search-items {
  padding-left: 62px;
}
.search-container {
  min-width: 480px;
  height: 52px;
  border: 2px solid #3a85fd;
  background-color: #fff;
  border-radius: 36px;
}
.search-container input {
  height: 98%;
}
// .tab-search {
//   min-width: 480px;
//   margin-top: 68px;
// }
.sear-tab-darks {
  padding: 6px 8px;
  border: 1px solid #1172fe;
  background: transparent;
  border-bottom: none;
}
.types .router-link-exact-active {
  padding: 6px 8px;
  color: #fff !important;
  border: 1px solid #1172fe;
  background: #1172fe;
  border-bottom: none;
}
.search-list {
  left: 0;
  top: 49px;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: #fff;
  z-index: 1;
}
.hot-lists {
  margin-top: 40px;
  z-index: -2;
}
@media (max-width: 460px) {
  .search-container {
    min-width: 90%;
  }
  .box {
    width: 96%;
  }
  .mb_myqiye{
    margin-left: -70px;
  }
  .tab-search {
  min-width: 100%;

}
}
</style>
