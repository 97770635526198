<template>
  <div>
    <div id="dialog" v-show="visible==true">
      <div class="dialog position-fixed w-100">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="dialog_bgc">
              <div class="">
                <div class="text-right">
                  <span class="d-inline-block dialog_close mr-2 mt-2 cursor_pionter" @click="close"></span>
                </div>
                <div class="pt-4 pb-5 row align-items-center justify-content-center">
                  <span class="dialog_pic d-inline-block dialog_success_pic" v-if="dialogData.iconType=='success'"></span>
                  <!--登陆成功的icon-->
                  <span class="dialog_pic d-inline-block dialog_tip_pic" v-if="dialogData.iconType=='error'"></span>
                  <!--登陆失败的icon-->
                  <span class="dialog_pic d-inline-block dialog_vip_login" v-if="dialogData.iconType=='vip'"></span>
                  <!--vip的icon-->
                  <span class="dialog_pic d-inline-block dialog_user_pic" v-if="dialogData.iconType=='noLogin'"></span>
                  <!--提示还没有登陆的icon-->
                  <div class="d-inline">
                    <span class="ml-4 fz-20 color-w" v-if="dialogData.iconType!='vip'">{{ dialogData.msg }}</span><!-- 提示弹框上面一行大字-->
                    <span class="ml-4 fz-14 d-block color-w" v-if="dialogData.iconType!='vip'">{{ dialogData.tips }}</span><!-- 提示弹框第二行小字-->
                    <span class="ml-4 fz-20 color-w" v-if="dialogData.iconType=='vip'">该项为<em class="color-f20">VIP</em>用户专属查询</span>
                    <!--当icon为vip的时候显示的文字-->
                    <span class="ml-4 fz-14 d-block color-w" v-if="dialogData.iconType=='vip'">是否升级为<em class="color-f20">VIP</em>用户?</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer  justify-content-center pt-5 pb-5">
              <span class="dialog_btn_sign float-right br_4 cursor_pionter  w-25 row justify-content-center align-items-center mr-3" v-show="dialogData.lefText!=''" @click="close">
                {{ dialogData.lefText }}
              </span>
              <router-link v-bind:to="String(dialogData.nextRouter)" tag="span" class="dialog_btn_sign float-right br_4 cursor_pionter  w-25  row justify-content-center align-items-center">
                {{ dialogData.rightText }}
              </router-link>
            </div>
            <div class="" v-show="dialogData.iconType=='vip'">
              <!--当icon为VIP的时候出现让客户可以跳转到充值页面的链接-->
              <router-link to="/BuyService" tag="span" class="link_vip_tip color-9a fz-12 border-bottom border-right  float-right mr-3 mb-3 cursor_pionter">了解VIP用户特权</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: ['dialogData'],
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    showDialog() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
  },
  created() {},
};
</script>
<style>
.dialog {
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999999;
}
.dialog_bgc {
  background: url('../../assets/img/dialog/dialog_bgc.png') no-repeat top center;
}
.modal-header {
  border-bottom: 0;
}
.dialog_pic {
  width: 4.5rem;
  height: 3.1rem;
}
.dialog_close {
  width: 1.6rem;
  height: 1.6rem;
  background: url('../../assets/img/dialog/dialog_close_prev.png') no-repeat
    center center;
}
.dialog_close:hover {
  background: url('../../assets/img/dialog/dialog_close_hover.png') no-repeat
    center center;
}
.dialog_btn_sign {
  height: 2.3rem;
  color: #4a81fd;
  border-radius: 25px;
  background-color: transparent;
  border: 1px solid #4a81fd;
}
.dialog_btn_sign:hover {
  color: #fff;
  background-color: #4a81fd;
}
.link_vip_tip:hover {
  color: #4a81fd;
  border-bottom: 1px solid #4a81fd !important;
}
</style>
